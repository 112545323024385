<template>
  <div class="dev-item">
    <div class="w">
      <div class="t">
        <!-- PolySmartChain桥 -->
        {{ $t("study.bridgeT") }}
      </div>
      <div class="p pfont">
        <!-- 区块链桥梁就像我们在物理世界中所知道的桥梁一样工作。正如物理桥梁连接两个物理位置一样，区块链桥梁连接两个区块链生态系统。桥梁通过信息和资产的传输促进区块链之间的通信。 -->

        {{ $t("study.bridgeP") }}
      </div>
      <a href="javascript:;">
        <div class="but" @click="uti.goPath(store.link.study.bridgeBut)">
          <!-- 探索PSC桥 -->
          {{ $t("study.bridgeB") }}
        </div>
      </a>
    </div>
  </div>
</template>
<script>
import Store from "../../store";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
};
</script>

<style lang="less" scoped>
.dev-item {
  height: 100vh;
  min-width: 1400px;
  margin-bottom: 250px;
  background-image: url("../../assets/images/v4/study/bridgeBG.png");
  background-size: 100% 100%;
  .w {
    height: 100vh;
    padding-top: 250px;
    box-sizing: border-box;
    text-align: center;
    .t {
      // height: 95px;width: 676px;
      font-size: 70px;
      font-weight: bold;
      color: #333333;
    }
    .p {
      width: 782px;
      height: 108px;
      font-size: 24px;
      font-weight: 500;
      color: #666666;
      margin: 0 auto;
      margin-top: 52px;
    }
    .but {
      display: inline-block;
      margin-top: 106px;
      padding: 0 70px;
      height: 54px;
      line-height: 54px;
      background: linear-gradient(-90deg, #10c1fc, #3f65f9);
      box-shadow: 0px 5px 20px 0px rgba(79, 136, 213, 0.5);
      border-radius: 27px;
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
</style>
